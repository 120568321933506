import React, { Suspense } from 'react';
import { Box } from '@mui/material';
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';
import TopProgressBar from '../basics/TopProgressBar';

const Admin = React.lazy(() => import('../pages/Admin'))
const AdminApplicationList = React.lazy(() => import('../pages/AdminApplicationList'))
const AdminApplicationManage = React.lazy(() => import('../pages/AdminApplicationManage'))
const AdminApplicationNew = React.lazy(() => import('../pages/AdminApplicationNew'))
const AdminImportList = React.lazy(() => import('../pages/AdminImportList'))
const AdminImportManage = React.lazy(() => import('../pages/AdminImportManage'))
const AdminImportNew = React.lazy(() => import('../pages/AdminImportNew'))
const AdminOAuthList = React.lazy(() => import('../pages/AdminOAuthList'))
const AdminOAuthManage = React.lazy(() => import('../pages/AdminOAuthManage'))
const AdminOAuthNew = React.lazy(() => import('../pages/AdminOAuthNew'))
const AdminUserList = React.lazy(() => import('../pages/AdminUserList'))
const AdminUserManage = React.lazy(() => import('../pages/AdminUserManage'))
const ConfirmForgotPassword = React.lazy(() => import('../pages/ConfirmForgotPassword'))
const ForceChangePassword = React.lazy(() => import('../pages/ForceChangePassword'))
const ForgotPassword = React.lazy(() => import('../pages/ForgotPassword'))
const ImportLanding = React.lazy(() => import('../pages/ImportLanding'))
const Logout = React.lazy(() => import('../pages/Logout'))
const MFAResponse = React.lazy(() => import('../pages/MFAResponse'))
const MyApps = React.lazy(() => import('../pages/MyApps'))
const SignIn = React.lazy(() => import('../pages/SignIn'))
const SignUp = React.lazy(() => import('../pages/SignUp'))
const UserAccountManagement = React.lazy(() => import('../pages/UserAccountManagement'))
const UserChangePassword = React.lazy(() => import('../pages/users/ChangePassword'))

export default function AppRoutes() {
    return (
        <Box sx={{position: 'absolute', minHeight: '100%', width: '100%'}}>
            <BrowserRouter>
                <Suspense fallback={<TopProgressBar />}>
                    <Routes>
                        <Route path="/" element={<SignIn />} />
                        <Route path="/admin" element={<Admin />} />
                        <Route path="/signin" element={<SignIn />} />
                        <Route path="/importlanding" element={<ImportLanding />} />
                        <Route path="/signup" element={<SignUp />} />
                        <Route path='/verifyemail' element={<SignUp />} />
                        <Route path='/verifyphonenumber' element={<SignUp />} />
                        <Route path="/mfaresponse" element={<MFAResponse />} />
                        <Route path='/manage' element={<UserAccountManagement />} />
                        <Route path="/myapps" element={<MyApps />} />
                        <Route path='/forgotpassword' element={<ForgotPassword eventMode='forgotPassword' />} />
                        <Route path='/resetpassword' element={<ForgotPassword eventMode='resetPassword' />} />
                        <Route path='/confirmforgotpassword' element={<ConfirmForgotPassword />} />
                        <Route path='/forcechangepassword' element={<ForceChangePassword />} />
                        <Route path='/profile/password' element={<UserChangePassword />} />
                        <Route path='/admin/application/manage/:targetapplication' element={<AdminApplicationManage />} />
                        <Route path='/admin/application/manage' element={<AdminApplicationList />} />
                        <Route path='/admin/application/new' element={<AdminApplicationNew />} />
                        <Route path='/admin/import/manage/:targetimport' element={<AdminImportManage />} />
                        <Route path='/admin/import/manage' element={<AdminImportList />} />
                        <Route path='/admin/import/new' element={<AdminImportNew />} />
                        <Route path='/admin/oauth/manage/:targetclient' element={<AdminOAuthManage />} />
                        <Route path='/admin/oauth/manage' element={<AdminOAuthList />} />
                        <Route path='/admin/oauth/new' element={<AdminOAuthNew />} />
                        <Route path='/admin/user/manage/:targetuser' element={<AdminUserManage />} />
                        <Route path='/admin/user/manage' element={<AdminUserList />} />
                        <Route path='/logout' element={<Logout />} />
                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                </Suspense>
            </BrowserRouter>
        </Box>
    )
}

import config from './config.json';
import BigInteger from './BigInteger'
import DateHelper from './DateHelper'
import { Buffer } from 'buffer';
import CryptoJS from 'crypto-js/core';
import Base64 from 'crypto-js/enc-base64';
import HmacSHA256 from 'crypto-js/hmac-sha256';
import SHA1 from 'crypto-js/sha1';
import jwt_decode from 'jwt-decode'
import i18n from './i18n'
import axios from  './axios'

// Use dummy function as default
export default function dummy() {
    console.log('Hello')
}

export function RedirectFromToken(idtoken) {
    let base64Url = idtoken.split('.')[1];
    let decoded = Buffer.from(base64Url, 'base64').toString()
    let parsedIdToken = JSON.parse(decoded)
    window.location.href = parsedIdToken.redirect_url + "?idtoken=" + idtoken
}

export function emailValid (eml) {
    return /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+[A-Za-z]+$/.test(eml);
}

export function phoneValid (value) {
    return /^[+][0-9]{5,15}$/.test(value);
}

export function urlValid(url) {
    return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(url);
}

export function postAPI(path, body, requestConfig) {
    // Pull in admin token if it is around
    let token = getURLQueryParam('id') || ""
    // Determine proper endpoint
    let endpoint = "https://" + window.location.host + "/api"
    if (window.location.hostname === 'localhost' || window.location.hostname.startsWith('192.168.')) {
        console.log(`Running in development mode, targeting ${config.endpoint} endpoint`)
        endpoint = config.endpoint;
    }

    // Build call structure
    if (!requestConfig) {
        requestConfig = {}
    }
    requestConfig.headers = {
        'accept': "application/json"
    }

    // If mockit, pass the mockit flag through to the API. API should allow the request regardless of auth, but return mocked data
    let mockit = getURLQueryParam('mockit') || ""
    if(mockit !== ""){
        requestConfig.headers.mockit = "true"
    }

    if (token !== "") {
        requestConfig.headers.Authorization = token
    }


    // Check for a session ID, include it in the request
    let sessionId = localStorage.getItem('session_id')
    if (sessionId) {
        if (!body) {
            body = {}
        }
        body.session_id = sessionId
    }

    // Check for language
    let languageCode = getLanguageCode()
    if (languageCode) {
        if (!body) {
            body = {}
        }
        body.ui_language = languageCode
    }

    return axios.post(endpoint + path, body, requestConfig)
}

export class passwordRequirements {
    constructor(password, confirmPassword, passwordPwned, setPasswordPwned) {
        this.password = password
        this.confirmPassword = confirmPassword
        this.passwordPwned = passwordPwned
        this.setPasswordPwned = setPasswordPwned
        /**
         *  NOTE passwordPwned states:
         *
         *  null = haven't tested password yet
         *  undefined = currently checking password
         *  true = password has been checked and is pwn'd (bad)
         *  false = password has been checked and is not pwn'd (good)
        */
    }

    lengthMet = () => {
        return this.password.length >= 10
    }

    containsUppercase = () => {
        return this.password.match(/[A-Z]/) !== null;
    }

    containsLowercase = () => {
        return this.password.match(/[a-z]/) !== null;
    }

    containsNumber = () => {
        return this.password.match(/[0-9]/) !== null;
    }

    containsSpecial = () => {
        return this.password.match(/[$^*.[\]{}()?"!@#%&/\\,><':;|_~`]/) !== null;
    }

    passwordsMatch = () => {
        return this.password.length > 0 && this.password === this.confirmPassword
    }

    checkPwnedPasswords = () => {
        this.setPasswordPwned(undefined)

        let passwordHash = SHA1(this.password).toString().toUpperCase()
        let hashPrefix = passwordHash.substring(0, 5)
        axios.get('https://api.pwnedpasswords.com/range/' + hashPrefix)
        .then((res) => {
            let hashSuffixes = res.data.split("\r\n")
            this.setPasswordPwned(hashSuffixes.some((elem) => {
                let hashSuffix, numBreaches
                [hashSuffix, numBreaches] = elem.split(':')
                let hash = (hashPrefix + hashSuffix).toUpperCase()
                if (hash === passwordHash) {
                    console.log("Password has been breached, has appeared " + numBreaches + " times")
                    return true
                }

                return false
            }))
        })
        .catch((err) => {
            console.error("error response from pwnedpasswords.com:")
            console.error(err)
            this.setPasswordPwned(null)
        })
    }

    allPasswordRequirementsMet = () => {
        let staticPasswordRequirementsMet = this.lengthMet() &&
                                            this.containsUppercase() &&
                                            this.containsLowercase() &&
                                            this.containsNumber() &&
                                            this.containsSpecial() &&
                                            this.passwordsMatch()

        if (staticPasswordRequirementsMet && this.passwordPwned === null) {
            this.checkPwnedPasswords()
        }

        return staticPasswordRequirementsMet && this.passwordPwned === false
    }
}

export function completeSRPSignIn(
    challengeParameters,
    password,
    authHelper,
    targetApplication,
    session,
    successCallback, 
    errorCallback
) {
    const dateHelper = new DateHelper()
    let username = challengeParameters.USER_ID_FOR_SRP;
    let serverBValue = new BigInteger(challengeParameters.SRP_B, 16);
    let salt = new BigInteger(challengeParameters.SALT, 16);

    authHelper.getPasswordAuthenticationKey(
        username,
        password,
        serverBValue,
        salt,
        (errOnHkdf, hkdf) => {
            // getPasswordAuthenticationKey callback start
            if (errOnHkdf) {
                console.error(errOnHkdf)
                // Retry
                return completeSRPSignIn(challengeParameters, password, authHelper, targetApplication, session, successCallback, errorCallback)
            }

            const dateNow = dateHelper.getNowString();

            const message = CryptoJS.lib.WordArray.create(
                Buffer.concat([
                    Buffer.from(authHelper.poolName, 'utf8'),
                    Buffer.from(username, 'utf8'),
                    Buffer.from(challengeParameters.SECRET_BLOCK, 'base64'),
                    Buffer.from(dateNow, 'utf8'),
                ])
            );
            const key = CryptoJS.lib.WordArray.create(hkdf);
            const signatureString = Base64.stringify(HmacSHA256(message, key));

            let body = {
                username: username,
                password_claim_secret_block: challengeParameters.SECRET_BLOCK,
                password_claim_signature: signatureString,
                timestamp: dateNow
            };

            if (targetApplication !== null) {
                body.target_application = targetApplication
            } else {
                body.target_application = '_useracctmgmt'
            }

            if (session !== null) {
                body.session = session
            }

            postAPI('/authresponse', body)
            .then(successCallback)
            .catch(errorCallback)
        }
    );
}

export function initiateSRPSignin(authHelper, email, targetApplication, successCallback, errorCallback) {
    // Clear the session ID
    localStorage.removeItem('session_id')

    authHelper.getLargeAValue((err, aValue) => {
        if (err) {
            console.error(err)
            // Retry
            return initiateSRPSignin(authHelper, email, targetApplication, successCallback, errorCallback)
        }

        let body = {
            username: email,
            srp_a: aValue.toString(16)
        };

        if (targetApplication !== null) {
            body.target_application = targetApplication
        } else {
            body.target_application = '_useracctmgmt'
        }

        if (authHelper.mfaMethod) body.mfa_method = authHelper.mfaMethod

        postAPI('/signin', body)
        .then((res) => {
            // Capture the session ID, store in local storage
            if (res.data && res.data.session_id) {
                localStorage.setItem('session_id', res.data.session_id)
            }

            successCallback(res)
        })
        .catch(errorCallback)
    })
}

function default_redirect(redirect_url, IdToken) {
    let currentUrl = new URL(window.location.href)
    if(localStorage.getItem('target_application') === '_useracctmgmt') {
        currentUrl.pathname = '/myapps'
    } else {
        if (redirect_url === undefined) {
            currentUrl.pathname = '/myapps'
        } else {
            let searchParams = new URLSearchParams({
                idtoken: IdToken,
                language: getLanguageCode()
            })

            if (getURLQueryParam('destination')) {
                searchParams.append('destination', getURLQueryParam('destination'))
            }

            currentUrl = new URL(`${redirect_url}?${searchParams.toString()}`)
        }
    }

    window.location.href = currentUrl.href
}

export function authenticationSuccessAction(redirect_url, IdToken, post_authentication_flow_action) {

    // If the central log stream isn't in the jwt our lambda triggers didn't fire
    // for now I am going to just have the system do nothing. My thought is that the
    // user will click the button again and correctly trigger the lambda. I would really
    // like to record this somehow
    var payload = jwt_decode(IdToken)
    if (payload.central_log_stream !== undefined) {
        console.log(payload.central_log_stream)
    }
    else {
        return "Error with redirect, please try again"
    }
    if (typeof post_authentication_flow_action === "string") {
        post_authentication_flow_action = JSON.parse(post_authentication_flow_action)
    }

    // Handle redirecting the user
    try {
        if (post_authentication_flow_action.Type === 'POST-Redirect' || post_authentication_flow_action.Type === 'POST-Redirect-Access') {
            // https://stackoverflow.com/questions/19064352/how-to-redirect-through-post-method-using-javascript/27766998
            var form = document.createElement('form');
            document.body.appendChild(form)
            form.method = "POST";
            form.action = redirect_url;

            var destination = document.createElement('input');
            destination.type = 'hidden';
            destination.name = "destination"
            destination.value = getURLQueryParam('destination');
            form.appendChild(destination);

            var languageCode = document.createElement('input');
            languageCode.type = 'hidden';
            languageCode.name = "language"
            languageCode.value = getLanguageCode();
            form.appendChild(languageCode);

            var input = document.createElement('input');
            input.type = 'hidden';
            input.name = "idtoken"
            input.value = IdToken;
            form.appendChild(input);

            if (post_authentication_flow_action.Type === 'POST-Redirect-Access') {
                // Also include the access and refresh tokens
                var accessToken = document.createElement('input');
                accessToken.type = 'hidden';
                accessToken.name = "accesstoken"
                accessToken.value = localStorage.getItem('AccessToken');
                form.appendChild(accessToken);
                var refreshToken = document.createElement('input');
                refreshToken.type = 'hidden';
                refreshToken.name = "refreshtoken"
                refreshToken.value = getRefreshToken();
                form.appendChild(refreshToken);
                var sessionId = document.createElement('input');
                sessionId.type = 'hidden';
                sessionId.name = "session_id"
                sessionId.value = getSessionId();
                form.appendChild(sessionId);
                var trustdevice = document.createElement('input');
                trustdevice.type = 'hidden';
                trustdevice.name = "trust_device"
                trustdevice.value = localStorage.getItem('trust_device', 'false');
                form.appendChild(trustdevice);
            }

            form.submit();
        }
        else {
            default_redirect(redirect_url, IdToken)
        }
    } catch (error) {
        console.log(error)
        console.log("Encountered an error with advanced post_authentication_flow_action reverting to default")
        // If I error out resort to the earlier functionality
        default_redirect(redirect_url, IdToken)
    }
    return "success"
}

export function getAccessToken() {
    return localStorage.getItem('AccessToken') || sessionStorage.getItem('AccessToken')
}

export function getSessionId() {
    return localStorage.getItem('session_id')
}

export function setLanguageCode(language) {
    console.log("Problem here")
    var langCode = language || 'en'
    if (config.ui_languages[language] === undefined) {
        langCode = 'en'
    }
    localStorage.setItem('i18next', langCode)
    i18n.changeLanguage(langCode)
}

export function getLanguageCode() {
    return localStorage.getItem('i18next') || 'en'
}

export function getIdToken() {
    return localStorage.getItem('IdToken') || sessionStorage.getItem('IdToken')
}

export function getRefreshToken() {
    return localStorage.getItem('RefreshToken') || sessionStorage.getItem('RefreshToken')
}

export function clearAccessToken() {
    localStorage.removeItem('AccessToken')
    sessionStorage.removeItem('AccessToken')
}

export function clearIdToken() {
    localStorage.removeItem('IdToken')
    sessionStorage.removeItem('IdToken')
}

export function clearRefreshToken() {
    localStorage.removeItem('RefreshToken')
    sessionStorage.removeItem('RefreshToken')
}

export function clearAllTokens() {
    clearAccessToken()
    clearIdToken()
    clearRefreshToken()
}

export function tokensExpiredOrMissing() {
    let tokens = [getAccessToken(), getIdToken()]
    for (let i in tokens) {
        let token = tokens[i]
        if (!token) {
            return true
        }

        let decodedToken = jwt_decode(token)
        let expiration = decodedToken.exp * 1000 // convert seconds to milliseconds
        if (expiration < Date.now()) {
            return true
        }
    }

    return false
}

export function getNewTokens(username, target_application) {
    clearAccessToken()
    clearIdToken()

    const body = {
        username: username || getUsername() || getEmail(),
        target_application: target_application,
        RefreshToken: getRefreshToken()
    }

    return postAPI("/refreshtoken", body)
    .then((res) => {
        localStorage.setItem('AccessToken', res.data.AuthenticationResult.AccessToken)
        localStorage.setItem('IdToken', res.data.AuthenticationResult.IdToken)
        localStorage.setItem('redirect_url', res.data.redirect_url)
        localStorage.setItem('post_authentication_flow_action', JSON.stringify(res.data.post_authentication_flow_action))

        return res
    })
    .catch((err) => {
        console.error(err.response)
        clearRefreshToken()
        throw err
    })
}

export function getURLQueryParam(paramName) {
    const queryParams = new URLSearchParams(window.location.search)
    return queryParams.get(paramName)
}

export function maintainParameters(includeQuestionMark=true) {
    var parameter_string = includeQuestionMark ? "?" : ""
    if (getURLQueryParam('destination')) {
        parameter_string += "&destination=" + getURLQueryParam('destination')
    }
    if (getURLQueryParam('targetapplication')) {
        parameter_string += "&targetapplication=" + getURLQueryParam('targetapplication')
    }
    return parameter_string
}

export function isApprovedRedirectTarget(uri) {
    try {
        var parsed = new URL(uri)
        for (var domain in config.approved_redirect_domains) {
            if (parsed.hostname.endsWith(config.approved_redirect_domains[domain])) {
                return true
            }
        }
        console.log("this domain is not included in the approved list of redirect targets")
    } catch (error) {
        console.log("error with isApprovedRedirectTarget: " + error.toString())
    }
    return false
}

export function getTargetApp() {
    return (getURLQueryParam('targetapplication') || getURLQueryParam('client_id') || '_useracctmgmt').toLowerCase()
}

const customAppHeaders = {
    crc: 'Customer Resource Center',
    essentials: 'Customer Resource Center',
    marketplace: 'Marketplace',
    velis: 'Singu FM'
}

export function needsCustomAppHeader() {
    const targetApplication = localStorage.getItem('target_application')
    return Object.keys(customAppHeaders).some((prefix) => { return targetApplication.startsWith(prefix) })
}

export function customAppHeader() {
    const targetApplication = localStorage.getItem('target_application')
    for (let prefix in customAppHeaders) {
      if (targetApplication.startsWith(prefix)) {
        return customAppHeaders[prefix].toUpperCase()
      }
    }
    return ''
}

export function getUsername() {
    let idToken = getIdToken()
    if (idToken) {
        let decodedToken = jwt_decode(idToken)
        return decodedToken.sub
    }

    return localStorage.getItem('username')
}

export function getEmail() {
    let emailParam = getURLQueryParam('email')
    if (emailParam !== null) {
        return decodeURIComponent(emailParam)
     }

    let idToken = getIdToken()
    if (idToken) {
        let decodedToken = jwt_decode(idToken)
        return decodedToken.email
    }

    return localStorage.getItem('email') || ''
}
